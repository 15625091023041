import { INIT_SESSION, CLOSE_SESSION } from '../Types'

export default function (state, action) {

  const { payload, type } = action

  switch (type) {
    case INIT_SESSION:
      return {
        ...state,
        usuario: payload
      }

    case CLOSE_SESSION:
      return {
        ...state,
         usuario: null
      }
  
    default:
      return state;
  }

}