import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './components/Login.Component';
import LoginState from './context/Login/Login.State';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <LoginState>
    <Routes>
      <Route path='/' element={<App />} />
      
        <Route path='/login' element={<Login />} />
      
      {/* <Route path='' element={} /> */}
    </Routes>
      </LoginState>
    </BrowserRouter>
  </React.StrictMode>
);
