import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Login from "./components/Login.Component";
import LoginContext from "./context/Login/Login.Context";
import LoginState from "./context/Login/Login.State";

import './app.css'


function App() {

  const { userSession } = useContext(LoginContext);
  const [isSession, setIsSession] = useState(false);

  useEffect(() => {
    if(sessionStorage.getItem('token') && sessionStorage.getItem('token')) {
      setIsSession(true);
    } else {
      setIsSession(false);
    }
  }, [])
  
  return (
    <>
    <div className="container-fluid">
      <div className="row">
        <div className="col-8">
        </div>
        <div className="col-4">
          {
            isSession?
            <span>{userSession.user}</span>
            :<Link to='/login'>Login</Link>
          }
          {/* <Link to='/login'>Login</Link> */}
        </div>
      </div>
    </div>
      <h1 className="logotext">ConmigoNo.cl</h1>
    </>
  );
}

export default App;
