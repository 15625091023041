import React, { useEffect, useReducer } from 'react'
import LoginContext from './Login.Context'
import LoginReducer from './Login.Reducer'

const LoginState = (props) => {

  const initialState = {
    usuario: []
  }

  const [state, dispatch] = useReducer(LoginReducer, initialState)

  useEffect(() => {
    if(sessionStorage.getItem('user') && sessionStorage.getItem('token')){
      dispatch({
        type: 'INIT_SESSION',
        payload: {
          token: sessionStorage.getItem('token'),
          user: sessionStorage.getItem('user')
        }
      })
    }
  }, [])
  

  const Sign_In = async (user, pass) => {
    // console.log('this is Inicio de Session', user, pass)
    // let url = 'http://localhost:4090/house/login';https://home-domotic.herokuapp.com/
    let url = 'https://home-domotic.herokuapp.com/house/login';
    const query = await fetch(url, {
      method: 'POST',
      headers: {
        'authorization': 'Marcuxo',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: user,
        pass: pass
      })
    });

    let respose = await query.json();
    
    if(respose.status === 200){
      // console.log(respose.token, respose.user)
      sessionStorage.setItem('user', respose.user);
      sessionStorage.setItem('token', respose.token);
      dispatch({
        type: 'INIT_SESSION',
        payload: {
          token: respose.token,
          user: respose.user
        }
      })
    }
    window.location.href = '/'
  }

  const CloseSession = async () => {
    dispatch({
      type: 'CLOSE_SESSION',
      payload: null
    })
  }

  return (
    <LoginContext.Provider value={{
      userSession: state.usuario,
      Sign_In, CloseSession
    }}>

      { props.children }

    </LoginContext.Provider>
  )
}

export default LoginState