import React, { useContext, useEffect, useState } from 'react'
import LoginContext from '../context/Login/Login.Context'
import TextField from '@mui/material/TextField';
import { Box, Button } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const Login= () => {
  const [complete, setComplete] = useState(false)
  const [DataForm, setDataForm] = useState({
    user: "",
    pass: ""
  })

  useEffect(() => {
    // console.log(DataForm)
    if(DataForm.pass && DataForm.pass.length >= 8){
      HandleSetForm(true);
    } else {
      HandleSetForm(false);
    }
  }, [DataForm])
  

  const { userSession, Sign_In, CloseSession } = useContext(LoginContext)
  const cerrarSession = async () => {
    CloseSession();
  }

  const HandleSetForm = async (bol) => {
    setComplete(bol)
  }

  const HandleSendForm = async () => {
    Sign_In(DataForm)
  }

  const temporal = async () => {
    console.log(userSession)
  }
  
  return (
    <>
      <div className='col-12'>
          {/* {
            userSession?
            <>
              <span className=' text-end'>{userSession.user}</span>
              <a className=' text-end' href='/' onClick={()=>cerrarSession()}>Salir</a>
            </>
            :<span className=' text-end'>LogIn</span>
          } */}
        {/* <br />
        <br />
        <br />
        <br />
        <h1>Crear formulario para el inicio de session</h1>
        <h5>ademas de crear ruta, componente y logica para la creacion de cuenta de usuario</h5> */}

      </div>
      <div className="d-flex align-items-center" style={{height:'100vh'}}>
          <div className="container">
            <div className="row">
              <div className="col-1 hidden-xs"></div>
              <div className="col-md-5 col-sm-12 d-flex align-items-center py-5">
                <img src="img/logo.PNG" alt="" className='img-fluid'/>
              </div>
              <div className="col-md-5 col-sm-12 text-center login-bg pt-5">
                <div className="row p-4">
                  <div className="col-12 py-2">
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <AccountCircleOutlinedIcon sx={{ color: 'white', mr: 1, my: 0.5 }} />
                      <TextField id="input-with-sx" name='user' fullWidth label="Usuario" variant="standard" value={DataForm.user} color='secondary'
                        onChange={(e)=>setDataForm({...DataForm, [e.target.name]: e.target.value})} />
                    </Box>
                  </div>
                  <div className="col-12 py-2">
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <LockOutlinedIcon sx={{ color: 'white', mr: 1, my: 0.5 }} />
                      <TextField id="input-with-sx" type='password' name='pass' fullWidth label="Clave" value={DataForm.pass} variant="standard" color='secondary'
                        onChange={(e)=>setDataForm({...DataForm, [e.target.name]: e.target.value})} />
                    </Box>
                  </div>
                  <div className="col-12 py-4">

                    {
                      complete?
                      <Button size='small'
                        color='success'
                        fullWidth 
                        variant="contained"
                        onClick={()=>HandleSendForm()}
                        >Entrar</Button>
                      :
                      <Button size='small'
                        color='success'
                        fullWidth 
                        variant="contained"
                        disabled>Entrar</Button>
                        
                    }
                    <Button
                      onClick={()=>temporal()}
                    >
                      Temp
                    </Button>
                   
                  </div>
                </div>
              </div>
              <div className="col-1 hidden-xs text-white"></div>
            </div>
          </div>
      </div>
    </>
  )
}

export default Login